@use './Globals.scss' as *;

body {
	font-family: 'Carter One', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	background-color: $color-pale-yellow;
	position: relative;
}

.App {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	min-height: 100vh;
}

.spacer {
	flex: 1;
}

.Toastify {
	span > a {
		&:link, &:visited {
			background: linear-gradient(
				to right,
				#4cd964,
				#5ac8fa,
				#007aff,
				#34aadc,
				#5856d6,
				#ff2d55
			);
			font-weight: 600;
			background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}
}

.Toastify__toast { font-family: 'Carter One' !important; }

.disabled {
	background-color: rgb(152, 152, 152) !important;
	cursor: default !important;
	
	.label, input, span {
		color: rgb(215, 215, 215) !important;
		pointer-events: none;
	}
}

.button {
	cursor: pointer;
	user-select: none;

	> * {
		cursor: pointer;
		user-select: none;
	}
}

.img-disabled {
	filter: grayscale(0.75);
	cursor: default;
}

.labeled-textbox {
	display: flex;
	flex-direction: column;
	max-width: fit-content;
	background-color: $color-primary;
	padding: 0;
	box-sizing: border-box;
	border-radius: 0.5rem;

	span, input {
		color: $color-tertiary;
		text-align: center;
	}

	span {
		font-size: 0.625rem;
	}

	input {
		background-color: transparent;
		border: none;
		font-size: 0.875rem;
		font-family: 'Carter One';

		&:focus {
			outline: none;
		}
	}
}