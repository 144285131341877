@use '../../Globals.scss' as *;

.menu-bar {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background: $color-primary;
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25));
	padding-bottom: 1rem;
	border-radius: 0 0 2rem 2rem;
	box-shadow: $box-shadow-coords rgba($color-primary, 0.25);

	.logo {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		align-self: flex-start;
		margin: 10px 0 0 10px;

		.menu-img {
			background-image: url(../../assets/img/koraidon.png);
		}

		span {
			font-weight: 600;
			font-size: 2rem;
			color: $color-tertiary;
			margin-left: 1rem;
		}
	}

	.user-profile {
		margin: 0.75rem 1.25rem 0rem auto;
		cursor: pointer;

		.menu-img {
			background-image: url(../../assets/img/avatar.png);
		}

		.twitch-login-button {
			background-color: $color-secondary;
			color: $color-tertiary;
			padding: 0.2rem 0.4rem;
			border-radius: 0.5rem;
			box-shadow: $box-shadow-coords rgba($color-secondary, 0.25);
		}
	}

	.logout-menu {
		background-color: $color-tertiary;
		box-shadow: $box-shadow-coords rgba($color-black, 0.25);
		padding: 1rem;
		border-radius: 0.5rem;
		position: absolute;
		transform: translate(-50%, calc(64px));
		cursor: default;

		.logout-button, .admin-button, .switch-admin-button {
			background-color: $color-secondary;
			color: $color-tertiary;
			border-radius: 0.5rem;
			padding: 0.3rem 0.5rem;
			cursor: pointer;
		}

		.admin-button, .switch-admin-button {
			white-space: pre;
		}

		.logout-button, .switch-admin-button {
			margin-top: 0.5rem;

			span {
				display: block;
				text-align: center;
			}
		}
	}
}

.menu-img {
	position: relative;
	background-color: $color-tertiary;
	background-size: contain;
	min-width: 58px;
	min-height: 58px;
	border: 3px solid $color-tertiary;
	border-radius: 32px;
}