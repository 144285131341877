@use '../../Globals.scss' as *;

.choose-pkmn-section {
	position: relative;
	z-index: 1;

	.black-overlay {
		border-radius: 0.75rem; 
		z-index: 1;
	}
}

.choose-pkmn-section .search-bar {
	$shared-height: 2.25rem;

	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 1rem;

	input {
		width: 100%;
		height: calc($shared-height - (0.125rem * 2));
		border: none;
		padding: 0.125rem 0.5rem;
		background-color: $color-primary;
		border-radius: 0.5rem;
		box-shadow: $box-shadow-coords rgba($color-primary, 0.25);
		color: $color-tertiary;
		font-family: 'Carter One';

		&::placeholder {
			color: $color-tertiary;
		}
	}

	.search-input-wrapper {
		width: 100%;
		position: relative;
	}

	.search-icon {
		position: absolute;
		right: -0.5rem;
		top: 0.55rem;
		filter: drop-shadow(0 0.1rem 0.1rem rgba($color-black, 0.25));
	}

	.selected-pkmn {
		max-width: $shared-height;
		max-height: $shared-height;
		margin-left: 1.5rem;
	}

	.edit-button {
		display: flex;
		display: none;
		align-items: center;
		justify-content: center;
		min-width: fit-content;
		height: 2.125rem;
		margin-left: 1.5rem;
		background-color: $color-secondary;
		padding: 0 0.375rem;
		border-radius: 0.5rem;
		box-shadow: $box-shadow-coords rgba($color-secondary, 0.25);

		span {
			margin: auto 0;
			font-size: 0.75rem;
			color: $color-tertiary;
		}
	}
}