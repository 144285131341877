@use '../Globals.scss' as *;

.switch-admin-page {
	.section-subtitle {
		margin: 0.5rem 0 0.15rem 0;
	}
	.switch-status-wrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		column-gap: 1rem;
		row-gap: 0.25rem;

		.switch-status {
			display: flex;
			justify-content: center;
			align-items: center;

			&>span {
				display: block;
				text-align: center;
				font-size: 0.75rem;
			}

			.status-dot {
				margin-left: 0.375rem;
			}
		}
	}

	.controls {
		.start-all {
			background-color: $color-secondary;
			color: $color-tertiary;
			padding: 0.2rem 0.4rem;
			border-radius: 0.5rem;
			margin-top: 0.75rem;
			box-shadow: $box-shadow-coords rgba($color-secondary, 0.25);
		}
	}
}