$color-primary: rgba(#8166A2, 1);
$color-secondary: rgba(#E3A051, 1);
$color-tertiary: rgba(white, 1);
$color-pale-green: rgba(#E7FBBE, 1);
$color-pale-yellow: rgba(#FFFEEB, 1);
$color-black: black;

$max-mobile-width: calc(290px - 0.75rem * 2);
$title-text-shadow: 0 2px 4px rgba($color-primary, 0.2);
$box-shadow-coords: 0 0.375rem 0.5rem;

@font-face {
	font-family: 'Carter One';
	font-style: normal;
	font-weight: 600;
	src: url('../src/assets/fonts/CarterOne-Regular.ttf');
}