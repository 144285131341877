@use '../../Globals.scss' as *;

a:link, a:visited {
	text-decoration: none;
}

a:has(.join-twitch-button) {
	-webkit-tap-highlight-color: transparent;
}

.join-twitch-button {
	background-color: $color-pale-green;
	margin: 1rem auto 0 auto;
	padding: 0.75rem 1.25rem;
	border-radius: 0.75rem;
	min-width: fit-content;
	max-width: calc(290px - 0.75rem * 2);
	color: black;
	font-family: 'Carter One';
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: $box-shadow-coords rgba($color-pale-green, 0.25);
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;

	&:active {
		filter: brightness(0.97);
	}

	span {
		margin-left: 0.75rem;
		pointer-events: none;
		user-select: none;
	}
}