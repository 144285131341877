@use '../../Globals.scss' as *;

.footer {
	$icon-size: 1.25rem;

	display: flex;
	width: 100%;
	box-sizing: border-box;
	flex-direction: column;
	background-color: #685282;
	padding: 0.5rem;
	color: $color-tertiary;
	text-shadow: $title-text-shadow;

	.footer-title {
		font-size: 1.125rem;
	}

	.footer-title, .footer-options {
		margin-left: 1rem;
	}

	.footer-option:not(.credits) {
		display: flex;
		flex-direction: row;
		align-items: center;
		text-decoration: underline;

		span {
			margin-left: 0.4rem;
		}
	}

	.credits {
		max-width: fit-content;
		margin: 0 auto;

		a:link, a:visited {
			color: $color-tertiary;
			text-decoration: underline;
			font-size: 0.5rem;
		}
	}

	.icon-discord {
		width: $icon-size;
		height: calc($icon-size * 0.75);
		background-image: url(../../assets//img/discord.svg);
	}

	.icon-email {
		width: $icon-size;
		height: $icon-size;
		background-image: url(../../assets//img/at.svg);
	}
}