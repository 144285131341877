@use '../Globals.scss' as *;

.section {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: $color-tertiary;
	margin: 1.125rem auto;
	max-width: $max-mobile-width;
	padding: 1.25rem;
	border-radius: 0.75rem;
}

.section-title {
	font-family: 'Carter One';
	font-size: 1.25rem;
	font-weight: 600;
	color: $color-primary;
	text-shadow: $title-text-shadow;
}

.section-subtitle {
	font-family: 'Carter One';
	font-size: 1rem;
	font-weight: 600;
	color: $color-primary;
	text-shadow: $title-text-shadow;
}