@use '../Globals.scss' as *;

.admin-page {
	.queue {
		display: flex;
		flex-direction: column;
	}

	.wrapper {
		width: 100%;
	}

	.queue-title {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		.collapsed-indicator {
			margin-right: 0.6rem;
		}

		h1 {
			font-size: 1.5rem;
			text-align: center;
		}
	}

	.main-buttons {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		.button {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $color-secondary;
			padding: 0.2rem 0.3rem;
			margin: 0 0.5rem;
			border-radius: 0.5rem;
			font-size: 0.8rem;
			height: fit-content;
			box-shadow: $box-shadow-coords rgba($color-secondary, 0.25);

			span {
				width: 100%;
				text-align: center;
				color: $color-tertiary;
			}
		}

		.lock-button {
			padding: 0.3rem 0.5rem;
		}
	}

	.test-users-title {
		display: block;
		margin-top: 1rem;
		text-align: center;
	}

	.add-test-users {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.flex-row {
			display: flex;
			flex-direction: row;

			.add-to-normal, .add-to-legendary {
				background-color: $color-secondary;
				color: $color-tertiary;
				border-radius: 0.5rem;
				padding: 0.25rem;
				margin-top: 0.25rem;
			}

			.add-to-legendary {
				margin-left: 0.25rem;
			}
		}
	}

	.inputs-wrapper {
		margin-top: 1rem;

		.main-inputs {
			.labeled-textbox {
				margin: 0.5rem auto;
				width: fit-content;
			}
		}

		.update-button {
			margin: 1rem auto;
			max-width: fit-content;
			background-color: $color-secondary;
			box-shadow: $box-shadow-coords rgba($color-secondary, 0.25);
			color: $color-tertiary;
			padding: 0.3rem 0.4rem;
			border-radius: 0.5rem;

			&.disabled {
				box-shadow: $box-shadow-coords rgba(80, 80, 80, 0.25);
			}
		}
	}

	.queue-users {
		.section-title {
			display: block;
			text-align: center;
		}

		.enqueued {
			margin-top: 0.5rem;
		}

		.users {
			display: flex;
			flex-direction: column;

			.enqueued-user {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;

				.kick {
					padding: 0 0.25rem;
					color: rgb(233, 100, 83);
				}

				.username {
					margin-left: 0.25rem;
				}
			}
		}
	}
}