@use '../../../../Globals.scss' as *;

.choose-pkmn-section .customize-pkmn {
	position: absolute;
	width: 290px;
	max-width: 290px !important;
	top: 0;
	z-index: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 1rem 0.5rem 1rem 0.5rem;
	gap: 1rem;
	display: none;

	.black-overlay {
		border-radius: 0.75rem;
	}

	.customize-row {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.clickable {
		cursor: pointer;

		span, input {
			cursor: pointer;
		}
	}

	.labeled-textbox.shiny {
		$form-control-color: $color-primary;
  		$form-control-disabled: #959495;
		$form-background: $color-tertiary;

		min-width: 2.25rem;

		input[type="checkbox"] {
			/* Add if not using autoprefixer */
			-webkit-appearance: none;
			/* Remove most all native input styles */
			appearance: none;
			/* For iOS < 15 */
			background-color: $form-background;
			/* Not removed via appearance */
			margin: 0.2rem auto;
			
			font: inherit;
			color: #5f4b77;
			width: 1.15em;
			height: 1.15em;
			border: 0.15em solid currentColor;
			border-radius: 0.15em;
			transform: translateY(-0.075em);
			
			display: grid;
			place-content: center;
		}
		
		input[type="checkbox"]::before {
			content: "";
			width: 0.65em;
			height: 0.65em;
			clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
			transform: scale(0);
			transform-origin: bottom left;
			transition: 120ms transform ease-in-out;
			box-shadow: inset 1em 1em $form-control-color;
			/* Windows High Contrast Mode */
			background-color: CanvasText;
		}
		
		input[type="checkbox"]:checked::before {
			transform: scale(1);
		}
		
		input[type="checkbox"]:disabled {
			$form-control-color: $form-control-disabled;
			
			color: $form-control-disabled;
			cursor: not-allowed;
		}
	}

	.customize-row.ivs, .customize-row.evs {
		flex-direction: column;

		> span {
			color: $color-primary;
			text-shadow: $title-text-shadow;
		}

		.ivs-container, .evs-container {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.labeled-textbox {
				.label { font-size: 0.5rem; }
				max-width: 2.5rem;
			}
		}
	}

	.customize-row.moves {
		flex-direction: column;

		> span {
			color: $color-primary;
			text-shadow: $title-text-shadow;
		}

		.moves-grid {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 1fr 1fr;
			row-gap: 0.5rem;
			align-items: center;
			justify-items: center;

			.labeled-textbox {
				width: 8.725rem !important;
				max-width: 100%;
			}

			.move {
				min-height: 1.75rem;
				justify-content: center;
				padding: 0.4rem 0.5rem;
				
				span {
					font-size: 0.825rem;
				}
			}

			.move1, .move3 {
				justify-self: start;
			}

			.move2, .move4 {
				justify-self: end;
			}
		}
	}

	.customize-row.confirm {
		align-items: center;
		justify-content: center;

		.confirm-button {
			background-color: $color-secondary;
			color: $color-tertiary;
			font-size: 1.25rem;
			padding: 0.25rem 2rem;
			border-radius: 0.5rem;
			box-shadow: $box-shadow-coords rgba($color-secondary, 0.25);
			cursor: pointer;
		}
	}

	.level { max-width: 2.875rem; }
	.gender { max-width: 4.25rem; }
	.ability { max-width: 8.5rem; }
	.item { max-width: 9.2rem; }
	.shiny { max-width: 2.75rem; .checkbox-spacer { width: 2.625rem; } }
	.showdown {
		max-width: 4.75rem; 
		justify-content: center;

		span { font-size: 0.66rem; }
	}

	.edit-field-container {
		display: none;
		flex-direction: column;
		width: 90%;
		height: 75%;
		box-sizing: border-box;
		background-color: $color-tertiary;
		box-shadow: $box-shadow-coords rgba($color-black, 0.25);
		padding: 0.5rem;
		border-radius: 0.5rem;
		position: absolute;
		z-index: 95;

		.edit-header {
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			align-items: center;
			max-height: fit-content;

			.edit-controls {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				width: 100%;
				margin-top: 0.5rem;
	
				.save-button, .edit-back-button {
					display: flex;
					flex-direction: column;
					justify-content: center;
					background-color: $color-secondary;
					padding: 0.4rem 0.65rem;
					border-radius: 0.5rem;
					max-height: fit-content;
	
					span {
						color: $color-tertiary;
					}
				}

				.edit-back-button {
					margin-left: 0.5rem;
					background-color: $color-primary;
				}

				.save-button {
					margin-right: 0.5rem;
				}
			}

			.edit-field {
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin-top: 0.55rem;

				span {
					font-size: 1rem;
					color: $color-primary;
					text-shadow: $title-text-shadow;
				}

				input {
					background-color: $color-primary;
					border: none;
					font-size: 0.875rem;
					font-family: 'Carter One';
					color: $color-tertiary;
					text-align: center;
					border-radius: 0.5rem;
					height: 1.65rem;
					width: 80%;
					margin-top: 0.25rem;
		
					&:focus {
						outline: none;
					}

					&:disabled {
						filter: saturate(0.30);
					}
				}
			}
		}

		.entries {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: 1rem;
			overflow-y: scroll;

			.entry {
				width: 80%;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: row;
				background-color: #F6F6F6;
				padding: 0.5rem;
				margin: 0.5rem;
				border-radius: 0.5rem;
				box-shadow: $box-shadow-coords rgba($color-black, 0.15);

				.entry-icon {
					width: 1.5rem;
					height: 1.5rem;
					background-size: contain;
					background-position: center;
					margin-left: 0.5rem;
				}

				.entry-name {
					margin-left: 0.5rem;
					color: $color-primary;
				}
			}
		}
	}
}