@use '../Globals.scss' as *;

.trade-section {
	display: none;
	
	.message-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 1rem;

		.queue-name {
			font-size: 0.5rem;
		}

		.message {
			font-size: 2rem;
			line-height: 2.2rem;
		}

		.queue-name, .message {
			color: $color-secondary;
		}
	}

	.timeout {
		margin-top: 0.5rem;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		.time-left {
			display: block;
			margin-left: 0.5rem;
			width: 3.25rem;
		}
	}

	.actions {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-top: 2rem;

		.insert-code-button {
			background-color: $color-secondary;
			margin-right: 0.5rem;
		}

		.leave-button {
			background-color: $color-primary;
			margin-left: 0.5rem;
		}

		.insert-code-button, .leave-button {
			border-radius: 0.5rem;
			padding: 0.2rem 0.4rem;
			color: $color-tertiary;
		}
	}
}