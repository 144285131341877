@use '../Globals.scss' as *;

.pick-queue-section {
	position: relative;
	$border: 0.8rem;

	.queue-button-wrapper {
		position: absolute;
		width: calc($max-mobile-width + 4rem);
		top: 4rem;
		background: linear-gradient(to bottom, #F4D394, #F2B133);
		border-radius: $border;
		padding: 5px;
	}

	.queue-button {
		display: grid;
		grid-template-columns: auto 0.8fr;
		grid-template-rows: 1fr 0.9fr;
		align-items: end;
		background-color: $color-primary;
		background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25));
		border-radius: calc($border / 2);
		padding: 0.5rem 1rem;
		cursor: pointer;

		&.offline {
			filter: saturate(0.15);
			cursor: default !important;

			> * {
				cursor: default !important;
			}

			.bottom-left span {
				font-size: 2rem;
			}
		}

		&.locked {
			background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(255, 0, 0, 0.25));
		}

		.top-left span, .top-right span {
			color: $color-tertiary;
			text-shadow: 2px 2px $color-black;
		}

		.top-left {
			display: flex;
			flex-direction: column;
			justify-self: start;

			.queue-title {
				font-size: 1.375rem;
			}

			.switches-available, .est-wait {
				font-size: 0.75rem;
				text-shadow: 1px 1px $color-black;
			}
		}

		.top-right {
			display: flex;
			justify-self: end;
			align-self: start;

			span {
				font-size: 0.625rem;
				text-shadow: 1px 1px $color-black;
			}
		}

		.bottom-left {
			span {
				line-height: 2.625rem;
				font-size: 2.625rem;
				color: $color-tertiary;
				text-shadow: 2px 2px $color-black;
			}
		}

		.bottom-right {
			justify-self: end;

			span {
				font-size: 1.875rem;
				line-height: 1.875rem;
				color: $color-tertiary;
				text-shadow: 2px 2px $color-black;
			}
		}
	}

	.legendary-spacer {
		margin-top: 1rem;
		height: 160px;
	}

	.normal-queue-button {
		width: 94%;
		margin-top: 1.5rem;
	}

	.queue-button-wrapper, .normal-queue-button {
		box-shadow: $box-shadow-coords rgba($color-black, 0.2);
	}
}