@use '../../Globals.scss' as *;

.interactive-popup {
	max-width: 12rem;
	position: absolute;
	margin: auto auto;
	background-color: $color-primary;
	padding: 1rem 1.5rem;
	border-radius: 0.5rem;
	color: $color-tertiary;
	z-index: 2;
	flex-direction: column;
	top: 50vh;
	transform: translateY(-50%);

	.title {
		text-align: center;
		font-size: 1.25rem;
	}

	.message-body {
		padding: 1rem 0.5rem;
		text-align: center;
		
		input {
			color: $color-black;
			text-align: center;
			background-color: $color-tertiary;
			border: none;
			font-size: 0.875rem;
			font-family: 'Carter One';
			border-radius: 0.5rem;
			box-shadow: $box-shadow-coords rgba($color-tertiary, 0.25);
			margin-top: 0.5rem;
			padding: 0.25rem;

			&:focus {
				outline: none;
			}
		}
	}

	.confirm-actions {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		.button-cancel {
			margin-left: 0.5rem;
			background-color: $color-secondary;
			padding: 0.4rem 0.5rem;
			border-radius: 0.5rem;
			box-shadow: $box-shadow-coords rgba($color-secondary, 0.25);
		}

		.button-okay {
			margin-right: 0.5rem;
			background-color: $color-secondary;
			padding: 0.4rem 0.5rem;
			border-radius: 0.5rem;
			box-shadow: $box-shadow-coords rgba($color-secondary, 0.25);
		}
	}

	.input-actions {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		.button-go {
			background-color: $color-secondary;
			padding: 0.3rem 0.8rem;
			border-radius: 0.5rem;
			box-shadow: $box-shadow-coords rgba($color-secondary, 0.25);
		}
	}
}