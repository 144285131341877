@use '../Globals.scss' as *;

.enqueued-section {
	display: none;
	
	.info-edit {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		.est-wait {
			font-size: 0.75rem;
			color: $color-primary;
			text-shadow: $title-text-shadow;
		}

		.edit-pkmn {
			background-color: $color-secondary;
			color: $color-tertiary;
			font-size: 0.75rem;
			border-radius: 0.5rem;
			padding: 0.5rem 0.4rem;
			box-shadow: $box-shadow-coords rgba($color-secondary, 0.25);
			margin-left: 2.8rem;
			cursor: pointer;
		}
	}

	.queue-info {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 1rem;

		.queue-name {
			font-size: 0.5rem;
		}

		.queue-position {
			font-size: 2rem;
			line-height: 2.2rem;
		}

		.queue-name, .queue-position {
			color: $color-primary;
			text-shadow: $title-text-shadow;
		}
	}

	.leave-button {
		background-color: $color-secondary;
		color: $color-tertiary;
		font-size: 1.125rem;
		border-radius: 0.5rem;
		padding: 0.5rem 0.4rem;
		box-shadow: $box-shadow-coords rgba($color-secondary, 0.25);
		margin: 1rem 0 0.5rem 0;
		cursor: pointer;
	}
}