@use '../../../../Globals.scss' as *;

.choose-pkmn-section .search-results {
	position: absolute;
	width: $max-mobile-width;
	top: 0;
	z-index: 91;
	max-height: 18rem;
	display: none;

	.scroll-wrapper {
		display: flex;
		flex-direction: column;
		width: 100%;
		overflow-y: scroll;
	}

	.loading-grid {
		width: 3rem;
		height: 3rem;
		flex-shrink: 0;
	}

	.search-result {
		width: 100%;
		height: 4.375rem;
		display: flex;
		flex-direction: row;
		background-color: #F6F6F6;
		border-radius: 0.75rem;
		box-shadow: $box-shadow-coords rgba($color-black, 0.075);
		margin: 0.5rem auto;
		cursor: pointer;

		.pkmn-icon {
			height: 100%;
			width: 4.375rem;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 0.75rem;
			background-color: $color-primary;

			img {
				width: 3.375rem;
				height: 3.375rem;
			}
		}

		.pkmn-details {
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-left: 0.5rem;
			margin-bottom: 0.75rem;

			.pokedex-id {
				font-size: 0.75rem;
			}

			.pkmn-name {
				font-size: 1.25rem;
			}

			.pokedex-id, .pkmn-name {
				color: $color-primary;
				text-shadow: $title-text-shadow;
			}
		}
	}
}