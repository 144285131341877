@use '../../Globals.scss' as *;

.loading-grid {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.loading-grid div {
	position: absolute;
	width: 20%;
	height: 20%;
	border-radius: 50%;
	background: $color-primary;
	animation: lds-grid 1.2s linear infinite;
}
.loading-grid div:nth-child(1) {
	top: 10%;
	left: 10%;
	animation-delay: 0s;
}
.loading-grid div:nth-child(2) {
	top: 10%;
	left: 40%;
	animation-delay: -0.4s;
}
.loading-grid div:nth-child(3) {
	top: 10%;
	left: 70%;
	animation-delay: -0.8s;
}
.loading-grid div:nth-child(4) {
	top: 40%;
	left: 10%;
	animation-delay: -0.4s;
}
.loading-grid div:nth-child(5) {
	top: 40%;
	left: 40%;
	animation-delay: -0.8s;
}
.loading-grid div:nth-child(6) {
	top: 40%;
	left: 70%;
	animation-delay: -1.2s;
}
.loading-grid div:nth-child(7) {
	top: 70%;
	left: 10%;
	animation-delay: -0.8s;
}
.loading-grid div:nth-child(8) {
	top: 70%;
	left: 40%;
	animation-delay: -1.2s;
}
.loading-grid div:nth-child(9) {
	top: 70%;
	left: 70%;
	animation-delay: -1.6s;
}
@keyframes lds-grid {
	0%, 100% {
	  opacity: 1;
	}
	50% {
	  opacity: 0.5;
	}
}  